import { DsmIcon } from '@dsm-dcs/design-system-react';
import { Grid, Typography } from '@material-ui/core';
import { type FC, MouseEvent } from 'react';
import Can from '../../../modules/Auth/Can';
import { DsmButtonV2 } from '../helpers/DsmButton';
import { DSM_BLUE, DSM_DARK_GREY } from '../../../../_metronic/layout';
import featureFlags from '../../utils/featureFlags';

interface PageHeaderProps {
  title: string;
  description: string;
  canSubject: string;
  createButtonText: string;
  createHandler: () => Promise<void>;
}

const PageHeader: FC<PageHeaderProps> = ({
  title,
  description,
  canSubject,
  createButtonText,
  createHandler,
}) => {
  const handleCreateClick = (
    event: MouseEvent<HTMLDsmButtonElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault();
    createHandler().catch(console.error); // Handle any errors thrown by fetchData
  };

  const getAddButton = () => {
    if (canSubject === "Compound Feed" && featureFlags.feedV2Routing) {
      return (
        <DsmButtonV2
          href="/v2/myfeeds/new"
          style={{ float: 'right', marginTop: '20px' }}
        >
          <DsmIcon
            name="general/plus"
            style={{ paddingRight: '5px', height: '24px', width: '24px' }}
          />
          {createButtonText}
        </DsmButtonV2>
      );
    }
    return (
      <DsmButtonV2
        onClick={handleCreateClick}
        style={{ float: 'right', marginTop: '20px' }}
      >
        <DsmIcon
          name="general/plus"
          style={{ paddingRight: '5px', height: '24px', width: '24px' }}
        />
        {createButtonText}
      </DsmButtonV2>
    );
  }

  return (
    <Grid container style={{ paddingBottom: 24 }}>
      <Grid item xs={10}>
        <Typography
          variant="h4"
          style={{ color: `${DSM_BLUE}`, fontWeight: 500 }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: `${DSM_DARK_GREY} !important`,
          }}
        >
          {description}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        {Can('create', canSubject) && getAddButton()}
      </Grid>
    </Grid>
  );
};

export default PageHeader;
