import * as Yup from "yup";
import { StageType } from "../../../../../graphql/types";
import { defaultUnits } from "../../../../sustell_15/utils/unit-utils";
import { usedResourcesPart } from "./baselineValidationSchemaGeneralPart";
import { processingStageData } from "./processingBaselineValidation";
import {
  averageLiveweightMortality,
  feedItemsTest,
  feedItemsTestMandatory,
  numericOptionalWithGreaterThanMin,
  numericOptionalWithMin,
  numericOptionalWithMinMax,
  numericRequiredWithGreaterThanMin,
  numericRequiredWithMin,
  numericRequiredWithMinMax,
} from "./validationObjectBuilderFunctions";
import {
  ManureForm,
  SurfaceType,
} from "../../../../../graphql/generated/blonk/pigs";
import { FacilitySpecies } from "../../../../sustell_15/models/Facility/FacilityTypes";

const pigletsToStageAndSoldPigletsValidation = (intl) =>
  numericOptionalWithMin(intl, 0).test(
    "pigletsToStageAndSoldPiglets",
    "",
    function (value, testContext) {
      const { path, createError } = this;
      if (!value && testContext.parent) {
        if (
          !testContext.parent?.pigletsToStage &&
          !testContext.parent?.soldPiglets
        )
          return createError({
            path,
            message: intl.formatMessage({
              id: "VALIDATION.FIELD.OUTPUT.PIGLETS",
            }),
          });
      }
      return true;
    }
  );

const soldPigsValidation = (intl) =>
  numericOptionalWithMin(intl, 0).test(
    "soldPigsValidation",
    "",
    function (value, testContext) {
      const { path, createError } = this;
      if (!value && testContext.parent) {
        if (!testContext.parent?.soldPigs && !testContext.parent?.pigsToStage)
          return createError({
            path,
            message: intl.formatMessage({
              id: "VALIDATION.FIELD.OUTPUT.PIGS",
            }),
          });
      }
      return true;
    }
  );

const pigsToStageValidation = (intl) =>
  numericOptionalWithMin(intl, 0).test(
    "stageUsedAsInput",
    "",
    function (value, testContext) {
      const { path, createError } = this;
      if (!value && testContext.parent) {
        if (!testContext.parent?.soldPigs && !testContext.parent?.pigsToStage)
          return createError({
            path,
            message: intl.formatMessage({
              id: "VALIDATION.FIELD.OUTPUT.PIGS",
            }),
          });
      }
      // if the value is set, then check if this stage is used as input for some other stage
      // there are some validation triggers when testContext.from?.length === 3
      // and in such case entire form object is missing, skip checking in such cases
      if (value && testContext.from?.length === 4) {
        const entireForm =
          testContext.from?.[testContext.from.length - 1]?.value;
        const stageForm =
          testContext.from?.[testContext.from.length - 2]?.value;

        if (entireForm?.stages?.length && stageForm?.id) {
          const used = entireForm?.stages?.some((stage) => {
            // check is it used as input in processing stage, or internal input in some other stage
            // other UI logic should controle where each stage can be used as input
            const usedInProcessing =
              stage?.stageData?.processingInput?.originStageId === stageForm.id;
            const usedInStage = stage?.stageData?.input?.internalSources?.some(
              (source) => source.originStageId === stageForm.id
            );
            return usedInProcessing || usedInStage;
          });
          if (!used)
            return createError({
              path,
              message: intl.formatMessage({
                id: "SUSTELL.STAGE.PIGS.OUTPUT.TO_ANOTHER_STAGE_NOT_USED_ERROR",
              }),
            });
        }
      }

      return true;
    }
  );

export const stageInputBreeding = (intl, userUOM = defaultUnits) =>
  Yup.object({
    startDate: Yup.date().typeError(
      intl.formatMessage({ id: "VALIDATION.DATE.INPUT" })
    ),
    endDate: Yup.date()
      .typeError(intl.formatMessage({ id: "VALIDATION.DATE.INPUT" }))
      .min(
        Yup.ref("startDate"),
        intl.formatMessage({ id: "VALIDATION.DATE.RANGE_ERROR" })
      ),
    pigsPresentAtStart: numericRequiredWithMin(intl, 0),
    pigsPresentAtEnd: numericRequiredWithMin(intl, 0),
    internalSources: Yup.array().of(
      Yup.object({
        numberPigs: numericRequiredWithGreaterThanMin(intl, 0),
        originStageId: Yup.string().required(
          intl.formatMessage({ id: "VALIDATION.FIELD.INPUT_SELECT" })
        ),
        distanceTransport: numericOptionalWithMin(intl, 0),
        originAnimalType: Yup.string().when("hasBreedingInput", {
          is: "true" || true,
          then: Yup.string().required(
            intl.formatMessage({ id: "VALIDATION.FIELD.INPUT_SELECT" })
          ),
          otherwise: Yup.string().notRequired().nullable(),
        }),
      })
    ),
    externalSources: Yup.array().of(
      Yup.object({
        numberPigs: numericRequiredWithGreaterThanMin(intl, 0),
        averageAgeOfPigs: numericRequiredWithMinMax(intl, 5, 300),
        averageWeightOfPigs: numericRequiredWithMinMax(intl, 25, 350),
        distanceTransport: numericOptionalWithMin(intl, 0),
      })
    ),
  }).test("ckeckAnimalInputs", "", function test(value, testContext) {
    const internalSources = value.internalSources;
    const externalSources = value.externalSources;
    if (!internalSources && !externalSources) {
      return testContext.createError({
        path: `${testContext.path}`,
        message: intl.formatMessage({
          id: "VALIDATION.FIELD.INTERNAL_OR_EXTERNAL_SOURCES",
        }),
      });
    }
    if (internalSources?.length > 0) return true;
    if (externalSources?.length > 0) return true;
    return testContext.createError({
      path: `${testContext.path}`,
      message: intl.formatMessage({
        id: "VALIDATION.FIELD.INTERNAL_OR_EXTERNAL_SOURCES",
      }),
    });
  });

export const stageInputFattening = (intl, userUOM = defaultUnits) =>
  Yup.object({
    startDate: Yup.date().typeError(
      intl.formatMessage({ id: "VALIDATION.DATE.INPUT" })
    ),
    endDate: Yup.date()
      .typeError(intl.formatMessage({ id: "VALIDATION.DATE.INPUT" }))
      .min(
        Yup.ref("startDate"),
        intl.formatMessage({ id: "VALIDATION.DATE.RANGE_ERROR" })
      ),

    stockPresent: Yup.bool(),
    optionalInput: Yup.object().when("stockPresent", {
      is: true,
      then: Yup.object({
        pigsPresentAtStart: numericRequiredWithMin(intl, 0),
        pigsPresentAtEnd: numericRequiredWithMin(intl, 0).test(
          "animalNumberOK",
          "",
          function (value, testContext) {
            const { resolve, path, createError } = this;
            const pigsPresentAtStart =
              testContext.from[0]?.value?.pigsPresentAtStart;
            const animalsSold = testContext.from[2]?.value?.output?.soldPigs;
            const animalsToStage =
              testContext.from[2]?.value?.output?.pigsToStage;
            const animalsMortality =
              testContext.from[2]?.value?.output?.mortalityPigs;
            const internalIncomings =
              testContext.from[1]?.value.internalSources?.reduce(
                (sum, item) => {
                  if (item.numberPigs && Number(item.numberPigs))
                    return sum + Number(item.numberPigs);
                },
                0
              ) || 0;
            // console.log(testContext.from[1]?.value.externalSources);
            const externalIncomings =
              testContext.from[1]?.value.externalSources?.reduce(
                (sum, item) => {
                  if (item.numberPigs && Number(item.numberPigs))
                    return sum + Number(item.numberPigs);
                },
                0
              ) || 0;
            // console.log("internalIncomings, externalIncomings:",internalIncomings,externalIncomings);
            if (
              Number(pigsPresentAtStart) &&
              Number(animalsSold) &&
              Number(animalsToStage) + Number(animalsMortality)
            ) {
              const initialNumber =
                Number(pigsPresentAtStart) +
                internalIncomings +
                externalIncomings;
              const finalNumber =
                Number(value) +
                Number(animalsSold) +
                Number(animalsToStage) +
                Number(animalsMortality);
              // console.log(initialNumber, finalNumber)
              if (initialNumber === finalNumber) return true;

              return createError({
                path,
                message: intl.formatMessage({
                  id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.ANIMAL_NUMBER_ERROR",
                }),
              });
            }
            return true;
          }
        ),
        averageWeightPigsStart: numericRequiredWithMin(intl, 0),
        averageWeightPigsEnd: numericRequiredWithMin(intl, 0),
        averageAgePigsStart: numericRequiredWithMinMax(intl, 5, 300),
        averageAgePigsEnd: numericRequiredWithMinMax(intl, 5, 300),
      }),
    }),
    internalSources: Yup.array().of(
      Yup.object({
        numberPigs: numericRequiredWithGreaterThanMin(intl, 0),
        originStageId: Yup.string().required(
          intl.formatMessage({ id: "VALIDATION.FIELD.INPUT_SELECT" })
        ),
        distanceTransport: numericOptionalWithMin(intl, 0),
        originAnimalType: Yup.string().when("hasBreedingInput", {
          is: "true" || true,
          then: Yup.string().required(
            intl.formatMessage({ id: "VALIDATION.FIELD.INPUT_SELECT" })
          ),
          otherwise: Yup.string().notRequired().nullable(),
        }),
        averageAgeOfPigs: Yup.number(
          intl.formatMessage({ id: "VALIDATION.NUMERIC.INPUT" })
        ).when("hasBreedingInput", {
          is: "true" || true,
          then: numericRequiredWithMin(intl, 0).test(
            "lessThanOutputAverageAgeOfPigs",
            intl.formatMessage({ id: "VALIDATION.AVERAGE_AGE_PIGS_INPUT" }), // Custom message ID
            (value, context) => {
              const outputAverageAgeOfPigs =
                context.from[4]?.value?.stages[1]?.stageData.output
                  .averageAgePigs;
              return (
                !value ||
                !outputAverageAgeOfPigs ||
                value < outputAverageAgeOfPigs
              );
            }
          ),
          otherwise: Yup.number().notRequired().nullable(),
        }),
      })
    ),
    externalSources: Yup.array().of(
      Yup.object({
        numberPigs: numericRequiredWithGreaterThanMin(intl, 0),
        averageAgeOfPigs: numericRequiredWithMinMax(intl, 5, 300),
        averageWeightOfPigs: numericRequiredWithMin(intl, 0),
        distanceTransport: numericOptionalWithMin(intl, 0),
      })
    ),
  }).test("ckeckAnimalInputs", "", function test(value, testContext) {
    const internalSources = value.internalSources;
    const externalSources = value.externalSources;
    if (!internalSources && !externalSources) {
      return testContext.createError({
        path: `${testContext.path}`,
        message: intl.formatMessage({
          id: "VALIDATION.FIELD.INTERNAL_OR_EXTERNAL_SOURCES",
        }),
      });
    }
    if (internalSources?.length > 0) return true;
    if (externalSources?.length > 0) return true;
    return testContext.createError({
      path: `${testContext.path}`,
      message: intl.formatMessage({
        id: "VALIDATION.FIELD.INTERNAL_OR_EXTERNAL_SOURCES",
      }),
    });
  });

export const stageOutputFattening = (intl, userUOM = defaultUnits) =>
  Yup.object({
    averageAgePigs: numericRequiredWithGreaterThanMin(intl, 0),
    averageWeightPigs: numericRequiredWithGreaterThanMin(intl, 0),
    mortalityPigs: numericRequiredWithMin(intl, 0),
    pigsToStage: pigsToStageValidation(intl),
    soldPigs: soldPigsValidation(intl),
    priceSoldPigs: numericOptionalWithGreaterThanMin(intl, 0),
  });

export const stageHousing = (intl) =>
  Yup.object({
    surfaceType: Yup.string().required(),
    manureSystems: Yup.array()
      .of(
        Yup.object({
          localManureForm: Yup.string(),
          mmsType: Yup.string().required(
            intl.formatMessage({ id: "VALIDATION.FIELD.INPUT_SELECT" })
          ),
          mmsHoldingDuration: Yup.string().when("mmsType", {
            is: (val) => {
              // console.log("val", val);
              return (
                val === "LIQUID_COVER" ||
                val === "LIQUID_CRUST" ||
                val === "LIQUID_NO_CRUST" ||
                val === "PIT" ||
                val === "DEEP_BEDDING_ACTIVE_MIXING" ||
                val === "DEEP_BEDDING_NO_MIXING"
              );
            },
            then: Yup.string().required(
              intl.formatMessage({ id: "VALIDATION.FIELD.INPUT_SELECT" })
            ),
            otherwise: Yup.string().nullable(),
          }),
          share: Yup.number()
            .transform((changed, original) => {
              return original === "" ? undefined : changed;
            })
            .typeError(intl.formatMessage({ id: "VALIDATION.NUMERIC.INPUT" }))
            .test("sumOK", "", function (value, testContext) {
              const { resolve, path, createError } = this;
              const [parent1, parent2] = testContext.from;
              const localManureForm = resolve(
                parent1?.value?.localManureForm || ""
              );
              if (localManureForm) {
                const sameTypeMMSSystemsList = (
                  parent2?.value?.manureSystems || []
                ).filter((item) => item.localManureForm === localManureForm);
                // console.log(parent2);
                const sum = sameTypeMMSSystemsList.reduce((acc, item) => {
                  if (item.share && !Number.isNaN(item.share))
                    return acc + Number(item.share);
                  return acc;
                }, 0);
                // console.log(sum)
                if (sum !== 100) {
                  return createError({
                    path,
                    message: intl.formatMessage({
                      id: "SUSTELL.STAGE.PIGS.HOUSING.MMS.PERCENT_SUM_ERROR",
                    }),
                  });
                }
                return true;
              }
              return true;
            }),
        })
      )
      .min(1, intl.formatMessage({ id: "SUSTELL.HOUSING.MMS.MIN1.ERROR" }))
      // for non Deep Bedding surface type, at least one MMS must by slurry
      .test("MMSTypesCorect", "", function (values) {
        const { path, parent, createError } = this;
        const surfaceType = parent?.surfaceType;
        // if surface type isn't deep bedding, then it must exist at leate one slurry MMS
        if (surfaceType !== SurfaceType.DeepBedding) {
          const anyLiquid = values?.some(
            (mms) => mms.localManureForm === ManureForm.LiquidSlurry
          );
          if (values?.length && !anyLiquid) {
            // show error for at the first mms row
            const errorPath = `${path || ""}.[0].mmsType`;
            return createError({
              path: errorPath,
              message: intl.formatMessage({
                id: "SUSTELL.STAGE.PIGS.MANURE.LIQUID_MMS_MISSING_ERROR",
              }),
            });
          }
        }
        return true;
      }),
    Nreplacement: numericOptionalWithMinMax(intl, 0, 100),
    Preplacement: numericOptionalWithMinMax(intl, 0, 100),
    beddingSystems: Yup.array().of(
      Yup.object({
        beddingType: Yup.string(),
        beddingAmount: Yup.number().when("beddingType", {
          is: (val) => val,
          then: numericRequiredWithGreaterThanMin(intl, 0),
          otherwise: numericOptionalWithGreaterThanMin(intl, 0),
        }),
      })
    ),
    materials: Yup.array().of(
      Yup.object({
        materialType: Yup.string(),
        materialAmount: Yup.number().when("materialType", {
          is: (val) => val,
          then: numericRequiredWithGreaterThanMin(intl, 0),
          otherwise: numericOptionalWithGreaterThanMin(intl, 0),
        }),
      })
    ),
  });

export const stageOutputBreeding = (intl, userUOM = defaultUnits) =>
  Yup.object({
    averageWeightPigs: averageLiveweightMortality("Pig-Pigs", intl, userUOM),
    // numericRequiredWithMinMax(intl, 100,350),
    averageWeightPiglets: averageLiveweightMortality(
      "Pig-Piglets",
      intl,
      userUOM
    ),
    // numericRequiredWithMinMax(intl, 2,15),
    mortalityPigs: numericRequiredWithMin(intl, 0),
    mortalityPiglets: numericRequiredWithMin(intl, 0),
    pigsToStage: pigsToStageValidation(intl),
    pigletsToStage: pigletsToStageAndSoldPigletsValidation(intl),
    soldPigs: soldPigsValidation(intl),
    soldPiglets: pigletsToStageAndSoldPigletsValidation(intl),
    priceSoldPigs: numericOptionalWithGreaterThanMin(intl, 0),
    priceSoldPiglets: numericOptionalWithGreaterThanMin(intl, 0),
  });

export const stageEmissions = (intl) =>
  Yup.object({
    methaneEntericFermentation: numericOptionalWithMinMax(intl, -100, 100),
    methane: numericOptionalWithMinMax(intl, -100, 100),
    nitrousOxideDirect: numericOptionalWithMinMax(intl, -100, 100),
    nitrousOxideIndirect: numericOptionalWithMinMax(intl, -100, 100),
    amonia: numericOptionalWithMinMax(intl, -100, 100),
    nitricOxide: numericOptionalWithMinMax(intl, -100, 100),
    nonMethaneVolatile: numericOptionalWithMinMax(intl, -100, 100),
    PM10: numericOptionalWithMinMax(intl, -100, 100),
    PM25: numericOptionalWithMinMax(intl, -100, 100),
    ammoniaHousing: numericOptionalWithMinMax(intl, -100, 100),
    totalSuspendedParticles: numericOptionalWithMinMax(intl, -100, 100),
  });

export const stageFeed = (intl, userUOM = defaultUnits) =>
  Yup.object().shape(
    {
      compoundFeeds: Yup.array().when("singleIngredients", {
        is: (singleIngredients) =>
          singleIngredients === undefined ||
          (Array.isArray(singleIngredients) &&
            !singleIngredients.some(
              (item) => !isNaN(item.kgPerAnimal) && item.kgPerAnimal > 0
            )),
        then: feedItemsTestMandatory(intl, 0),
        otherwise: feedItemsTest(intl, 0),
      }),
      singleIngredients: Yup.array().when("compoundFeeds", {
        is: (compoundFeeds) =>
          compoundFeeds === undefined ||
          (Array.isArray(compoundFeeds) &&
            !compoundFeeds.some(
              (item) => !isNaN(item.kgPerAnimal) && item.kgPerAnimal > 0
            )),
        then: feedItemsTestMandatory(intl, 0),
        otherwise: feedItemsTest(intl, 0),
      }),
    },
    ["singleIngredients", "compoundFeeds"]
  ); // to avoid circular dependency error

// stage fields validation rules
export const stageDataPartPig = ({ intl, userUOM = defaultUnits }) =>
  Yup.object({
    stages: Yup.array()
      .of(
        Yup.object({
          id: Yup.string(),
          name: Yup.string()
            .required(intl.formatMessage({ id: "VALIDATION.NAME.REQUIRED" }))
            .min(
              3,
              intl.formatMessage(
                { id: "VALIDATION.FIELD.MIN_LENGTH" },
                { count: 3 }
              )
            ),
          type: Yup.string()
            .oneOf([
              StageType.Breeding,
              StageType.Fattening,
              StageType.Processing,
            ])
            .required(),
          facilityType: Yup.string()
            .nullable(true)
            .when("type", {
              is: StageType.Processing,
              then: Yup.string().required(
                intl.formatMessage({ id: "VALIDATION.FIELD.REQUIRED" })
              ),
            }),
          facilityId: Yup.string()
            .nullable(true)
            .when("type", {
              is: StageType.Processing,
              then: Yup.string().required(
                intl.formatMessage({ id: "VALIDATION.FIELD.REQUIRED" })
              ),
            }),
          stageData: Yup.object()
            .when("type", {
              is: StageType.Breeding,
              then: Yup.object({
                input: stageInputBreeding(intl, userUOM),
                housing: stageHousing(intl, userUOM),
                feed: stageFeed(intl, userUOM),
                output: stageOutputBreeding(intl, userUOM),
                emissions: stageEmissions(intl),
              }),
            })
            .when("type", {
              is: StageType.Fattening,
              then: Yup.object({
                input: stageInputFattening(intl, userUOM),
                housing: stageHousing(intl, userUOM),
                feed: stageFeed(intl, userUOM),
                output: stageOutputFattening(intl, userUOM),
                emissions: stageEmissions(intl),
              }),
            })
            .when("type", {
              is: StageType.Processing,
              then: processingStageData(intl, FacilitySpecies.Pig),
            }),
        })
      )
      .required()
      .min(1, intl.formatMessage({ id: "SUSTELL.STAGE.MIN.REQUIRED" })),
  });

// merge all necessary parts to baseSchema
const assembleValidationSchemaSustell = (baseSchema, confObj) => {
  const infoObject = baseSchema;
  const combinedSchema = Yup.object({ info: infoObject })
    .concat(Yup.object({ resourceUse: usedResourcesPart(confObj) }))
    .concat(stageDataPartPig(confObj));
  return combinedSchema;
};

export default assembleValidationSchemaSustell;
