import { type FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useIntl } from '../../../_metronic/i18n/customUseIntl';
import useAdobeDataLayer from '../analytics/adobeDataLayer';
import CompoundFeedsTable from '../components/CompoundFeeds/CompoundFeedsTable';
import { CompoundFeedAndCompoundFeedV2 } from '../components/CompoundFeeds/CompoundFeeds.types';
import PageHeader from '../components/headerV2/PageHeader';
import {
  GetAllCompoundFeedsV2,
  useFetchAllCompoundFeedsV2,
  useFetchCompoundFeeds,
} from '../../../api';
import countryIsoCodes from '../components/dashboard/isoCodes';
import { useSubheader } from '../../../_metronic/layout';
import featureFlags from '../utils/featureFlags';

interface CompoundFeedsPageProps {
  match: {
    params: {
      customerID: string;
    };
  };
}

const CompoundFeedsPage: FC<CompoundFeedsPageProps> = ({ match }) => {
  const intl = useIntl();
  const { ctaClickEvent, formStartEvent } = useAdobeDataLayer();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const subheader = useSubheader();

  const { customerID } = match.params;

  const { data: dataV1, isLoading: isLoadingV1 } =
    useFetchCompoundFeeds(customerID);
  const { data: dataV2, isLoading: isLoadingV2, fetchNextPage, hasNextPage } =
    useFetchAllCompoundFeedsV2(customerID);

  const breadCrumbsTitle = intl.formatMessage({ id: 'SUSTELL.MY_FEEDS' });
  useEffect(() => {
    const breadCrumbs: { pathname?: string; title: string }[] = [];
    breadCrumbs.push({
      pathname: `/customers/${customerID}/myfeeds`,
      title: breadCrumbsTitle,
    });
    // Disable eslint due to non-converted (js) subheader
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    subheader.setBreadcrumbs(breadCrumbs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerID, breadCrumbsTitle]);

  const mappedV2Data: CompoundFeedAndCompoundFeedV2[] = []
    dataV2?.pages?.forEach(
      (value: GetAllCompoundFeedsV2) =>
        value?.listCompoundFeedsV2?.items?.forEach(el => {
          if (el) {
            mappedV2Data.push({
              ...el,
              year: {
                value: el?.year,
              },
              target_species: el?.targetSpecies,
              is_reference: false,
              modified_date: el?.updatedAt,
              feedmill_location: {
                iso_code: el?.feedmillLocation,
                name: countryIsoCodes[
                  el?.feedmillLocation as keyof typeof countryIsoCodes
                  ],
              },
              databaseFoundation: el?.databaseFoundation,
              type: el?.type,
            } as unknown as CompoundFeedAndCompoundFeedV2);
          }
        })
    );
  const originalFeedData: CompoundFeedAndCompoundFeedV2[] =
    (dataV1?.data?.getCompoundFeedList
      ?.items as unknown as CompoundFeedAndCompoundFeedV2[]) || [];
  const compoundFeedsList: CompoundFeedAndCompoundFeedV2[] =
    originalFeedData.concat(mappedV2Data);

  const createFeed = async () => {
    await ctaClickEvent(
      window.location.href,
      'button',
      'Create Feed',
      'My Feeds',
      'My Feeds'
    );
    history.push({
      pathname: `/customers/${customerID}/compoundfeeds`,
      state: {},
    });
    await formStartEvent('Create Feed', 'add');
  };

  return (
    <>
      <PageHeader
        title={intl.formatMessage({
          id: 'COMPOUNDFEEDS.MY_FEEDS',
        })}
        description={intl.formatMessage({
          id: 'COMPOUNDFEEDS.MY_FEEDS_DESCRIPTION',
        })}
        canSubject="Compound Feed"
        createButtonText={intl.formatMessage({
          id: 'COMPOUNDFEEDS.ADD_NEW_FEED',
        })}
        createHandler={createFeed}
      />
      <CompoundFeedsTable
        feedsList={compoundFeedsList}
        loading={isLoadingV1 && isLoadingV2}
        customerId={customerID}
        fetchNextPage={fetchNextPage}
        hasNextPage={!!hasNextPage}
      />
    </>
  );
};

export default CompoundFeedsPage;
