import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
import { Popover, MenuItem, Box, Tooltip } from '@material-ui/core';
import { MouseEvent, useState, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router';
import { CompoundFeedDatabase, FeedFootprintType } from '../../../../graphql/types';
import Can from '../../../modules/Auth/Can';
import { getUIDatabaseFoundation } from '../../utils/compound-feed-utils';
import { footprintURL } from '../../utils/footprint-url-utils';
import { isFeedV2Database } from '../CompoundFeedWizard/utils';
import useAdobeDataLayer from '../../analytics/adobeDataLayer';
import { CompoundFeedAndCompoundFeedV2 } from './CompoundFeeds.types';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import featureFlags from '../../utils/featureFlags';

interface CompoundFeedMoreTableActionsProps {
  customerId: string;
  feed: CompoundFeedAndCompoundFeedV2;
  item: CompoundFeedAndCompoundFeedV2;
  handleEdit: (item: CompoundFeedAndCompoundFeedV2) => Promise<void>;
  handleDuplicate: (item: CompoundFeedAndCompoundFeedV2) => Promise<void>;
  handleDelete: Dispatch<SetStateAction<null | CompoundFeedAndCompoundFeedV2>>;
}

const CompoundFeedMoreTableActions = ({
  customerId,
  feed,
  item,
  handleEdit,
  handleDuplicate,
  handleDelete,
}: CompoundFeedMoreTableActionsProps) => {
  const { ctaClickEvent, formStartEvent } = useAdobeDataLayer();
  const history = useHistory();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLDsmButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLDsmButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyToNewDbHandler = (databaseFoundation: CompoundFeedDatabase) => {
    ctaClickEvent(
      window.location.href,
      'link',
      'Copy Feed to V2',
      'My Feeds',
      'My Feeds'
    ).catch((e) => console.log(e));
    const feedCopyName = `${feed.name} - COPY ${getUIDatabaseFoundation(
      databaseFoundation
    )}`;
    history.push({
      pathname: `/customers/${customerId}/compoundfeeds/new`,
      search: `?step=0&database=${getUIDatabaseFoundation(
        // feed.databaseFoundation
        databaseFoundation
      )}`,
      state: {
        // customerName: customerName,
        formType: 'new',
        newDuplicateFeed: true,
        formValueFeedName: feedCopyName,
        feedID: feed.id,
        copyV1ToV2Db: databaseFoundation,
      },
    });
    formStartEvent('Copy Feed to V2', 'add').catch((e) => console.log(e));
  };

  const openFootprint = () => {
    ctaClickEvent(
      window.location.href,
      'button',
      'Open Feeds Footprint',
      'My Feeds',
      'My Feeds'
    ).catch((e) => console.log(e));
    history.push(
      footprintURL({
        baselineCustomerID: customerId,
        baselineFarmID: '',
        baselineProcessID: '',
        baselineType: 'f',
        baselineReference: feed.id,
      }),
      {
        dataSetName: feed.name,
        databaseName: getUIDatabaseFoundation(feed.databaseFoundation),
      }
    );
  };

  const openDashboard = () => {
    ctaClickEvent(
      window.location.href,
      'button',
      'Open My Dashboard',
      'My Feeds',
      'My Feeds'
    ).catch((e) => console.log(e));
    history.push({
      pathname: '/mydashboard/overview',
      state: {},
    });
  };

  const handleEditClick = (
    event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault();
    handleEdit(item).catch((e) => console.error(e));
  };

  const handleDuplicateClick = (
    event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault();
    handleDuplicate(item).catch((e) => console.error(e));
  };

  const getFootprintBox = () => {
    if (
      featureFlags.feedV2Routing && 
      feed.type && 
      feed.type === FeedFootprintType.Footprint
    ) {
      return (
        <a href={`/v2/myfeeds/footprint/upload/${feed.id}`}>
          <Box
            style={{
              marginTop: '-3px',
              lineHeight: '2em',
              fontWeight: '500',
              padding: '0 15px',
              borderRadius: '1em',
              color: 'var(--dsm-color-success)',
              backgroundColor: 'var(--dsm-color-success-lighter)',
              border: '1px solid var(--dsm-color-success)',
            }}
          >
            {intl.formatMessage({
              id: 'COMPOUNDFEEDS.MY_FEEDS.OPEN_FOOTPRINT',
            })}
          </Box>
        </a>
      );
    }
    return (
      <Box
        onClick={openFootprint}
        style={{
          marginTop: '-3px',
          lineHeight: '2em',
          fontWeight: '500',
          padding: '0 15px',
          borderRadius: '1em',
          color: 'var(--dsm-color-success)',
          backgroundColor: 'var(--dsm-color-success-lighter)',
          border: '1px solid var(--dsm-color-success)',
        }}
      >
        {intl.formatMessage({
          id: 'COMPOUNDFEEDS.MY_FEEDS.OPEN_FOOTPRINT',
        })}
      </Box>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {Can('read', 'Footprint') && getFootprintBox()}
      <Tooltip title={intl.formatMessage({ id: 'GENERAL.MORE_ACTIONS' })}>
        <DsmButton
          variant="text"
          iconOnly
          disabled={false}
          style={{ marginLeft: '12px' }}
          onClick={handleClick}
        >
          <DsmIcon
            slot="before"
            name="general/dots-vertical"
            style={{ color: 'black' }}
          />
        </DsmButton>
      </Tooltip>
      <Popover
        id="popup-menu-feed"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {Can('read', 'Compound Feed') && (
          <MenuItem style={{ fontWeight: '500' }} onClick={openDashboard}>
            <DsmIcon
              name="charts/bar-chart-square-01"
              style={{
                marginRight: ' 5px',
                height: '16px',
                width: '16px',
              }}
            />
            {intl.formatMessage({
              id: 'COMPOUNDFEEDS.MY_FEEDS.OPEN_MY_DASHBOARD',
            })}
          </MenuItem>
        )}
        {Can('update', 'Compound Feed') && !item.benchmark && (
          <MenuItem style={{ fontWeight: '500' }} onClick={handleEditClick}>
            <DsmIcon
              name="general/edit-01"
              style={{
                marginRight: ' 5px',
                height: '16px',
                width: '16px',
              }}
            />
            {intl.formatMessage({
              id: 'GENERAL.EDIT',
            })}
          </MenuItem>
        )}
        {Can('create', 'Compound Feed') && (
          <MenuItem
            style={{ fontWeight: '500' }}
            onClick={handleDuplicateClick}
          >
            <DsmIcon
              name="general/copy-03"
              style={{
                marginRight: ' 5px',
                height: '16px',
                width: '16px',
              }}
            />
            {intl.formatMessage({
              id: 'GENERAL.DUPLICATE',
            })}
          </MenuItem>
        )}
        {Can('create', 'Compound Feed') &&
          !isFeedV2Database(feed.databaseFoundation) && (
            // && isGfliCompatible(item.target_species)
            <MenuItem
              style={{ fontWeight: '500' }}
              onClick={() => copyToNewDbHandler(CompoundFeedDatabase.Gfli)}
            >
              <DsmIcon
                name="general/copy-07"
                style={{
                  marginRight: ' 5px',
                  height: '16px',
                  width: '16px',
                }}
              />
              {intl.formatMessage({
                id: 'COMPOUNDFEEDS.COPY_TO_2_GFLI',
              })}
            </MenuItem>
          )}
        {Can('create', 'Compound Feed') &&
          !isFeedV2Database(feed.databaseFoundation) && (
            //  && isAfp6Compatible(item.target_species)
            <MenuItem
              style={{ fontWeight: '500' }}
              onClick={() => copyToNewDbHandler(CompoundFeedDatabase.Afp_6_3)}
            >
              <DsmIcon
                name="general/copy-07"
                style={{
                  marginRight: ' 5px',
                  height: '16px',
                  width: '16px',
                }}
              />
              {intl.formatMessage({
                id: 'COMPOUNDFEEDS.COPY_TO_2_AFP6',
              })}
            </MenuItem>
          )}
        {Can('delete', 'Compound Feed') && (
          <MenuItem
            style={{ fontWeight: '500', color: 'var(--dsm-color-error' }}
            onClick={() => handleDelete(item)}
          >
            <DsmIcon
              name="general/trash-03"
              style={{
                marginRight: ' 5px',
                height: '16px',
                width: '16px',
                color: 'var(--dsm-color-error',
              }}
            />
            {intl.formatMessage({
              id: 'GENERAL.DELETE',
            })}
          </MenuItem>
        )}
      </Popover>
    </div>
  );
};

export default CompoundFeedMoreTableActions;
